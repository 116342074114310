<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Lista de Produtos</h4>

          <div class="page-title-right">
            <b-button
              variant="success"
              @click.prevent="$router.push({ name: 'produto' })"
            >
              <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
              Adicionar
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left">
      <div class="col-12">
        <div class="card">
          <div
            class="card-header bg-white border-bottom d-flex align-items-center justify-content-between"
          >
            <h5 class="card-title">
              <i class="fas fa-filter text-dark mr-2"></i>
              Filtro
            </h5>
            <b-button
              variant="light"
              v-b-tooltip.hover
              title="Limpar Filtro"
              @click.prevent="limparFiltro()"
            >
              <i class="bx bx-sync font-size-16 align-middle"></i>
            </b-button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-4">
                <label>Nome</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Nome do produto"
                    for="text"
                    v-model="filtro.search"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4">
                <label>Status</label>
                <multiselect
                  v-model="status"
                  :options="options_status"
                  class="helo"
                  label="text"
                  placeholder="Status"
                  select-label="Selecionar"
                  deselect-label="Remover"
                ></multiselect>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-2 mt-lg-0">
                <label>Categoria</label>
                <multiselect
                  v-model="categorys_parent"
                  :options="options_categorys"
                  class="helo"
                  label="name"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :loading="isLoading"
                  @search-change="searchCategory"
                ></multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="loading">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" v-else-if="error">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" v-else-if="produts.length <= 0">
        <div class="card">
          <div class="card-body py-5">
            <h5 class="mb-3">Nenhum item encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
      <div class="col-12" v-else>
        <div class="row">
          <div
            v-for="(data, index) in produts"
            :key="data.id"
            class="col-xl-3 col-lg-4 col-sm-6"
          >
            <div class="card">
              <div class="card-body">
                <div class="product-img position-relative">
                  <div class="avatar-sm product-ribbon">
                    <span
                      class="avatar-title rounded-circle"
                      :class="data.status == 0 ? 'bg-success' : 'bg-danger'"
                    >
                      <i class="fas fa-eye" v-if="data.status == 0"></i>
                      <i class="fas fa-eye-slash" v-else></i>
                    </span>
                  </div>
                  <router-link v-if="data.image != null && data.image != ''" tag="a" :to="`/detalhes-do-produto/${data.id}`">
                    <span v-for="img in data.image" :key="img.id">
                      <img
                        :src="img.image300"
                        v-if="img.capa == 1"
                        alt
                        loading="lazy"
                        class="img-fluid mx-auto d-block"
                      />
                    </span>
                  </router-link>
                  <router-link v-else tag="a" :to="`/detalhes-do-produto/${data.id}`">
                      <img
                        src="@/assets/images/notpro.jpg"
                        alt
                        loading="lazy"
                        class="img-fluid mx-auto d-block"
                      />
                  </router-link>
                </div>
                <div class="mt-4 text-center">
                  <h5 class="mb-3">
                    <router-link
                      tag="a"
                      class="text-dark"
                      :to="`/detalhes-do-produto/${data.id}`"
                    >
                      {{ data.name }}
                    </router-link>
                  </h5>
                  <p class="text-muted">
                    <i
                      class="fas fa-star text-warning mr-1"
                      v-for="index in data.start"
                      :key="index"
                    ></i>
                    <i
                      class="fas fa-star text-muted mr-1"
                      v-for="index in (5 - data.start)"
                      :key="index + data.start"
                    ></i>
                  </p>
                  <h5
                    class="my-0"
                    v-if="
                      dataPromo(
                        data.discount_date_start,
                        data.discount_date_end,
                      )
                    "
                  >
                    <span
                      class="text-muted mr-2"
                      v-if="data.discount_price > 0"
                    >
                      <del>{{ data.price | currency('R$ ')}}</del>
                    </span>
                    <b>{{ data.discount_price | currency('R$ ') }}</b>
                  </h5>
                  <h5 class="my-0" v-else>
                    <b>{{ data.price | currency('R$ ') }}</b>
                  </h5>
                </div>
                <div class="mt-4 text-center">
                  <b-button
                    variant="success"
                    @click.prevent="
                      $router.push({
                        name: 'detalhes-do-produto',
                        params: { id: data.id },
                      })
                    "
                    v-b-tooltip.hover
                    title="Visualizar Produto"
                  >
                    <i class="fas fa-eye"></i>
                  </b-button>
                  <b-button
                    variant="info"
                    class="ml-2"
                    v-b-tooltip.hover
                    title="Editar Produto"
                    @click.prevent="
                      $router.push({
                        name: 'produto-edit',
                        params: { id: data.id },
                      })
                    "
                  >
                    <i class="fas fa-edit"></i>
                  </b-button>
                  <b-button
                    variant="danger"
                    class="ml-2"
                    v-b-tooltip.hover
                    title="Remover Produto"
                    @click.prevent="removerItem(index, data)"
                  >
                    <i class="fas fa-trash"></i>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="row pb-5" v-if="loadingbtn">
      <div class="col-12 text-center">
        <b-button variant="info" @click.prevent="carregarMais()">
          <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
          Carregar Mais
        </b-button>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner" class="text-info"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
export default {
  components: {
    Multiselect,
    InfiniteLoading,
  },
  data() {
    return {
      status: null,
      categorys_parent: null,
      options_status: [
        { text: 'Ativo', value: 0 },
        { text: 'Bloqueado', value: 1 },
      ],
      options_categorys: [],
      filtroCategory: {
        limit: 10,
      },
      filtro: {
        limit: 20,
        page: 1,
      },
      isLoading: false,
      produts: [],
      loading: true,
      error: false,
      loadingbtn: true
    }
  },
  created() {
    this.listCategorySelect()
  },
  watch: {
    'filtro.search': function () {
      this.listProduct()
    },
    status: function () {
      if (this.status != null) {
        this.filtro.status = this.status.value
        this.listProduct()
      }
    },
    categorys_parent: function () {
      if (this.categorys_parent != null) {
        this.filtro.category_id = this.categorys_parent.id
        this.listProduct()
      }
    },
  },
  methods: {
    ...mapActions('category', ['ActionCategoryAll']),
    ...mapActions('product', ['ActionProductAll', 'ActionProductUpdate']),
     async carregarMais() {
      try {
        this.loadingbtn = true
        await this.ActionProductAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.produts.push(...res.data.data.list)
          } else {
            this.loadingbtn = false
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.produts = []
        this.loading = false
        this.error = true
      }
    },
    async listCategorySelect() {
      try {
        this.isLoading = true
        await this.ActionCategoryAll(this.filtroCategory).then((res) => {
          this.options_categorys = res.data.data.list
          this.isLoading = false
        })
      } catch (_) {
        this.options_categorys = []
      }
    },
    searchCategory(e) {
      this.filtroCategory.search = e
      this.listCategorySelect()
    },
    async infiniteHandler($state) {
      try {
        this.loadingbtn = true
        await this.ActionProductAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.produts.push(...res.data.data.list)
            $state.loaded()
          } else {
            this.loadingbtn = false
            $state.complete()
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.produts = []
        $state.complete()
      }
    },
    async listProduct() {
      try {
        this.filtro.page = 1
        this.loadingbtn = true
        await this.ActionProductAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page = 2
            this.produts = res.data.data.list
          } else {
            this.loadingbtn = false
            this.produts = []
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        this.produts = []
      }
    },
    limparFiltro() {
      this.filtro = {
        limit: 10,
        page: 1,
      }
      this.status = { value: 0, text: 'Ativo' }
      this.listProduct()
    },
    removerItem(index, data) {
      this.$swal({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja Remover Este Item?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `SIM`,
        denyButtonText: `NÃO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removerItemUp(index, data)
        }
      })
    },
    async removerItemUp(index, data) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Removendo item',
          icon: 'info',
          showConfirmButton: false,
        })
        var t = data
        data.trash = 1
        data.category_id = data.category.id
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionProductUpdate(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
        this.produts.splice(this.produts.indexOf(t), 1)
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    dataPromo(start, end) {
       var dia = new Date()
      var d = new Date(start)
      var df = new Date(end)
      if(d <= dia){
          if(dia <= df){
              return true
          }
          return false
      }
    },
  },
}
</script>
